import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  useParams,
  usePathname,
  useSearchParams,
  useSelectedLayoutSegments,
} from 'next/navigation';

import { useCurrentUser } from '../user/useCurrentUser';
import { RQ_BAG_BASIC_KEY } from '../ReactQuery/cacheKeys';
import { useExtendedRouter } from '../routing/useExtendedRouter';

import { ADD_TO_BAG_NO_SIZE_QUERY_PARAM } from './constants';
import { getBagBasic, updateBag } from './helpers';
import { FetchBagBasicResponse, UpdateBagRequest } from './types';

import { constructDynamicRoute } from '@/modules/routing/constructDynamicRoute';

type Params = {
  productId?: number;
  sellerId?: number;
};

export function useBagBasic({ productId, sellerId }: Params = {}) {
  const router = useExtendedRouter();
  const [authenticated, currentUser] = useCurrentUser();
  const [queryEnabled, setQueryEnabled] = useState(false);
  const pathname = usePathname();
  const queryParams = useSearchParams();
  const params = useParams();
  const segments = useSelectedLayoutSegments();
  const viewName = constructDynamicRoute(params, segments);

  const variantId = queryParams.get('add-to-bag');

  /**
   * @TECHDEBT CP 8/1/24
   * updateBag currently includes a local storage update for unauth'd bag handling
   * This should be separated out from our mutation logic. See WEB-4270.
   */
  const updateBagMutation = useMutation({
    mutationFn: (data: UpdateBagRequest) => updateBag(authenticated, data),

    onSettled: () => {
      // Remove `add-to-bag` from the url params
      router.replace(`/products/${pathname}/`);
      setQueryEnabled(true);
    },
  });

  const query = useQuery<{
    count: number;
    products: FetchBagBasicResponse['products'];
  }>({
    queryKey: [RQ_BAG_BASIC_KEY],
    enabled: queryEnabled,
    queryFn: async () => {
      const res = await getBagBasic(authenticated);

      return {
        count: res.count,
        products: res.products,
      };
    },
  });

  function handleProductPageLoad() {
    // no `add-to-bag` url param
    if (!variantId) {
      setQueryEnabled(true);
      return;
    }

    if (productId && sellerId && sellerId !== currentUser?.id) {
      updateBagMutation.mutate([
        {
          product_id: productId,
          seller_id: sellerId,
          variant_id:
            variantId === ADD_TO_BAG_NO_SIZE_QUERY_PARAM
              ? undefined
              : Number(variantId),
          quantity: 1,
        },
      ]);
    }
  }

  function handlePageLoad() {
    const onProductPage = viewName === '/products/[slug]';

    if (onProductPage) {
      handleProductPageLoad();
    } else {
      setQueryEnabled(true);
    }
  }

  useEffect(() => {
    handlePageLoad();
  }, []);

  return { query };
}
