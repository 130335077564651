'use client';

import React, { ReactNode } from 'react';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

export type NudgePillProps = {
  icon?: ReactNode;
  text: string;
  testId?: string;
  className?: string;
};

export function NudgePill({ icon, text, testId, className }: NudgePillProps) {
  return (
    <div data-testid={testId} className={`${className} ${styles.container}`}>
      {icon}
      <Text className={styles.nudgeText} type="caption1">
        {text}
      </Text>
    </div>
  );
}
