'use client';

import React from 'react';
import { BagBoldIcon } from '@depop/web-ui-kit/Icons/BagBoldIcon';
import { useTranslations } from 'next-intl';

import { NudgePill, NudgePillProps } from '@/components/NudgePill';
import { getComputedNudgesCount } from '@/modules/nudges/helpers/getComputedNudgesCount';

type Props = Pick<NudgePillProps, 'className'> & {
  count?: number;
};

export function InBagsNudge({ className, count }: Props) {
  const t = useTranslations('bag');

  if (!count) {
    return null;
  }

  return (
    <NudgePill
      testId="inBagsNudgeWrapper"
      className={className}
      icon={<BagBoldIcon size={16} />}
      text={t('BagNudge', {
        count: getComputedNudgesCount(count),
      })}
    />
  );
}
