import {
  BackendProductStatus,
  LegacyProductStatus,
  ProductStatus,
} from '@/modules/product/types';

export function isSold(status: ProductStatus | string) {
  return (
    status === ProductStatus.MARKED_AS_SOLD ||
    status === ProductStatus.PURCHASED ||
    status === LegacyProductStatus.MARKED_AS_SOLD ||
    status === LegacyProductStatus.PURCHASED ||
    status === BackendProductStatus.MARKED_AS_SOLD ||
    status === BackendProductStatus.PURCHASED
  );
}
