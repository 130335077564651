import { cache } from 'react';
import { QueryClient } from '@tanstack/react-query';

export const getQueryClient = cache(
  () =>
    new QueryClient({
      /* Important defaults - these ensures data set during SSR does not expire */
      defaultOptions: {
        queries: { staleTime: Infinity, gcTime: Infinity },
      },
    })
);
