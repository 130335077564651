import { addDays } from 'date-fns';
import { getCookie, setCookie } from 'cookies-next';

import { CachedExperiments, FetchExperimentsResponse } from './types';
import { getPreviewExperiments } from './api';

import { AxiosCompatibleResponse } from '@/modules/http/types';
import { User } from '@/modules/user/types';
import { getPersistentId } from '@/modules/activityTracker/helpers';
import { isServerCheck } from '@/modules/env/isServerCheck';
import {
  FORCE_EXPERIMENT_VARIANTS_NAME,
  FORCE_VARIANT_FLAG_NAME,
} from '@/constants/cookies';
import { getQueryClient } from '@/modules/ReactQuery/helpers';
import { RQ_PREVIEW_EXPS_CACHE } from '@/modules/ReactQuery/cacheKeys';
import { X_COUNTRY_CODE } from '@/constants/headers';
import {
  OPTIMIZELY_EXPERIMENTS,
  OpzlyExperimentName,
} from '@/modules/experiments/config';
import { SERVERSIDE_getUserData } from '@/modules/user/SERVERSIDE_getUserData';
import { recordMetric } from '@/modules/observability/metrics';
import { Stdv1Stat } from '@/modules/observability/constants';

const headers = import('next/headers');

export async function getPopulatedPreviewExperiments(
  user?: User | null,
  countryCode?: string
) {
  // await dispatch(UserActions.getUser(request, ctx.res as any) as any);
  // only use auth in this call if we have a user in state
  const withAuth = Boolean(user && Object.keys(user).length);
  let res: AxiosCompatibleResponse<FetchExperimentsResponse> | null = null;
  try {
    const persistentId = await getPersistentId();

    res = await getPreviewExperiments({
      countryCode: countryCode || '',
      persistentId: persistentId || '',
      withAuth,
    });
    const { data } = res;
    // if the ua indicates a bot, we get a 202 response with no data
    // so this will handle rather than falling to the catch which was
    // filling up the logs
    const responseData = res.status === 202 ? {} : data;

    /**
     * Transforms the response to add a 'bucketed' flag to each experiment
     * as we will need to toggle these when JIT bucketing occurs
     */
    return Object.keys(responseData).reduce<CachedExperiments>((acc, val) => {
      acc[val] = { decision: responseData[val].variant, bucketed: false };
      return acc;
    }, {});
  } catch {
    recordMetric({
      type: 'increment',
      stat: Stdv1Stat['experiments.preview_request.prefetch.failed'],
    });
    return null;
  }
}

const COOKIE_CONFIG = {
  path: '/',
  encode: (v: string) => v,
};

export async function setForceVariantCookie() {
  const headersFunction = await headers;
  if (isServerCheck()) {
    (await headersFunction.cookies()).set(
      FORCE_VARIANT_FLAG_NAME,
      'true',
      COOKIE_CONFIG
    );
  } else {
    setCookie(FORCE_VARIANT_FLAG_NAME, 'true', COOKIE_CONFIG);
  }
}

export async function getForceVariantCookie() {
  const headersFunction = await headers;

  if (isServerCheck()) {
    return (await headersFunction.cookies()).get(FORCE_VARIANT_FLAG_NAME)
      ?.value;
  }

  return getCookie(FORCE_VARIANT_FLAG_NAME);
}

export async function setForcedVariantExperiments(experimentNames: string[]) {
  const value = experimentNames.join(',');
  const headersFunction = await headers;

  if (isServerCheck()) {
    (await headersFunction.cookies()).set(
      FORCE_EXPERIMENT_VARIANTS_NAME,
      value,
      {
        ...COOKIE_CONFIG,
        expires: addDays(new Date(), 7),
      }
    );
  } else {
    setCookie(FORCE_EXPERIMENT_VARIANTS_NAME, value, {
      ...COOKIE_CONFIG,
      expires: addDays(new Date(), 7),
    });
  }
}

export async function getForcedVariantExperiments() {
  const headersFunction = await headers;

  if (isServerCheck()) {
    return (await headersFunction.cookies()).get(FORCE_EXPERIMENT_VARIANTS_NAME)
      ?.value;
  }
  return getCookie(FORCE_EXPERIMENT_VARIANTS_NAME);
}

export async function prefetchPreviewExperiments() {
  const headersFunction = await headers;

  const userData = await SERVERSIDE_getUserData();
  const queryClient = getQueryClient();

  return queryClient.prefetchQuery({
    queryKey: [RQ_PREVIEW_EXPS_CACHE],
    queryFn: async () => {
      return await getPopulatedPreviewExperiments(
        userData,
        (await headersFunction.headers()).get(X_COUNTRY_CODE) || 'US'
      );
    },
  });
}

/**
 * By providing the experiment names you are interested in and the cached experiments dictionary
 * this helper will return the decision for all experiments and provide a fallback decision for
 * the requested experiment names if no cached experiments is supplied.
 *
 * This helper is already used by the useExperimentsV2 hook under the hood so ordinarily will not
 * be needed in client components.
 *
 * If you need to access an experiment decision server-side then use SERVERSIDE_getExperimentsDecision instead.
 */
export function getExperimentsDecision(
  experimentNames: OpzlyExperimentName[],
  cachedExperiments?: CachedExperiments
) {
  /**
   * We build this object of fallback experiments from the passed
   * list to return if the cachedExperiments variable is undefined.
   */
  const fallbackExps = experimentNames.reduce<CachedExperiments>(
    (acc, expName) => {
      acc[expName] = {
        decision: OPTIMIZELY_EXPERIMENTS[expName]?.control,
        bucketed: false,
      };
      return acc;
    },
    {}
  );

  /**
   * We merge the fallback exps which is built from the list passed into the helper
   * with the cached experiments to ensure that for any experiments passed into this
   * helper which don't exist in the cache we return the control variants so rendering
   * is unaffected.
   */
  const experimentsToReturn = { ...fallbackExps, ...cachedExperiments };

  /**
   * Transforms result into object of { experiment_name: "control|variant|etc" }
   * for easy consumption by consumers
   */
  return Object.keys(experimentsToReturn).reduce<Record<string, string>>(
    (acc, exp) => {
      acc[exp] = experimentsToReturn[exp].decision;
      return acc;
    },
    {}
  );
}

/**
 * A server-side version of getExperimentDecision that allows us to get an experiment decision
 * in a page or some other server component.
 *
 * For example:
 * const { web_4867_prioritise_vital_checkout_info } = await SERVERSIDE_getExperimentsDecision(
 *  ['web_4867_prioritise_vital_checkout_info']
 * );
 */
export async function SERVERSIDE_getExperimentsDecision(
  experimentNames: OpzlyExperimentName[]
) {
  // In next.js app router child components load before parents which means
  // we can't guarantee that the call to prefetch preview exp's in
  // ReactQueryHydrate will happen first
  await prefetchPreviewExperiments();
  const previewCache = getQueryClient().getQueryData<CachedExperiments>([
    RQ_PREVIEW_EXPS_CACHE,
  ]);
  return getExperimentsDecision(experimentNames, previewCache);
}
