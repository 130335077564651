const headers = import('next/headers');

import {
  CONTROL_IDENTIFIER,
  PREVIEW_EXPERIMENTS,
} from '@/modules/experiments/config';
import {
  FetchExperimentsBody,
  FetchExperimentsResponse,
} from '@/modules/experiments/types';
import { isServerCheck } from '@/modules/env/isServerCheck';
import http from '@/modules/http';
import { AxiosCompatibleResponse, RequestConfig } from '@/modules/http/types';
import {
  BUCKET_EXPERIMENTS_ENDPOINT,
  PREVIEW_EXPERIMENTS_ENDPOINT,
} from '@/constants/endpoints';
import {
  getForceVariantCookie,
  getForcedVariantExperiments,
} from '@/modules/experiments/helpers';
import {
  H_BUCKET_TIME,
  H_DEPOP_DEVICE_ID,
  H_SESSION_ID,
  X_COUNTRY_CODE,
} from '@/constants/headers';

function getOverride(experimentName: string, overrideCookie?: string) {
  try {
    if (!overrideCookie) {
      return undefined;
    }

    if (overrideCookie === '1') {
      return CONTROL_IDENTIFIER;
    }

    const experimentOverride = overrideCookie.split(',').find((experiment) => {
      const [experimentOverrideName] = experiment.split(':');
      return experimentOverrideName === experimentName;
    });

    const [, newExperimentVariant] = experimentOverride
      ? experimentOverride.split(':')
      : [];

    if (newExperimentVariant) {
      return newExperimentVariant;
    }

    return undefined;
  } catch {
    return undefined;
  }
}

async function overridePreviewExperiments(
  previewExperiments: FetchExperimentsBody
): Promise<FetchExperimentsBody> {
  const overrides = await getForcedVariantExperiments();
  const isForcedVariants =
    (await getForceVariantCookie()) === 'true' || overrides?.length;
  if (!isForcedVariants) {
    return previewExperiments;
  }

  return {
    flags: previewExperiments.flags.map(({ name }) => {
      const override = getOverride(name, overrides);
      return {
        name,
        ...(override ? { override } : {}),
      };
    }),
  };
}

export async function getPreviewExperiments(args: {
  countryCode: string;
  persistentId: string;
  withAuth?: boolean;
}): Promise<AxiosCompatibleResponse<FetchExperimentsResponse>> {
  const headersFunction = await headers;
  const { countryCode, persistentId, withAuth = true } = args;
  const reqHeaders: RequestConfig['headers'] = {
    [H_DEPOP_DEVICE_ID]: persistentId,
    [X_COUNTRY_CODE]: countryCode,
  };
  /**
   * This call happens server-side so need to make sure we set the device's
   * UA instead of the axios UA for this request.
   */
  if (isServerCheck()) {
    reqHeaders['user-agent'] =
      (await headersFunction.headers()).get('user-agent') || 'Unknown UA';
  } else {
    reqHeaders['user-agent'] = navigator.userAgent;
  }

  const previewExperiments =
    await overridePreviewExperiments(PREVIEW_EXPERIMENTS);

  return http.post(PREVIEW_EXPERIMENTS_ENDPOINT, previewExperiments, {
    withAuth,
    headers: reqHeaders,
    // timeout: 2500,
  });
}

export type BucketUserArgs = {
  experimentNames: string[];
  countryCode: string;
  persistentId: string;
  sessionId: string;
  date: number;
};

export function bucketUserExperiments({
  experimentNames,
  countryCode,
  persistentId,
  sessionId,
  date,
}: BucketUserArgs): Promise<AxiosCompatibleResponse<FetchExperimentsResponse>> {
  const body = experimentNames.map((experimentName) => ({
    name: experimentName,
  }));

  return http.post<FetchExperimentsResponse>(
    BUCKET_EXPERIMENTS_ENDPOINT,
    {
      flags: body,
    },
    {
      withAuth: true,
      headers: {
        [H_DEPOP_DEVICE_ID]: persistentId,
        [X_COUNTRY_CODE]: countryCode,
        [H_BUCKET_TIME]: date.toString(),
        [H_SESSION_ID]: sessionId,
      },
    }
  );
}
