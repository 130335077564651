import {
  getAuthBagBasic,
  getAuthBagDetailed,
  getGuestBagDetailed,
  updateAuthBag,
} from './api';
import { GUEST_BAG_ITEMS_LOCAL_STORAGE_KEY } from './constants';
import {
  BagBasicProduct,
  BagDetailedCache,
  BagDetailedProduct,
  BagDetailedSellerCache,
  BagDetailedSellerInfo,
  BagViewArgs,
  UpdateBagProduct,
  UpdateBagRequest,
} from './types';

import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@/modules/storage';
import { CheckoutProduct } from '@/modules/checkout/types';
import { AxiosCompatibleResponse } from '@/modules/http/types';
import { PictureFormat } from '@/modules/product/types';
import { filterRequiredPictures } from '@/modules/pictures/helpers';
import { clearCheckoutProducts } from '@/modules/checkout/helpers';
import { isSold } from '@/modules/product/helpers/isSold';
import { CurrencyCode } from '@/modules/currencies/constants';
import { safeJsonParse } from '@/modules/storage/helpers';

type ShippingPriceParams = {
  nationalShippingCost?: string;
  internationalShippingCost?: string;
  sellerLocation: string;
  buyerLocation?: string;
};

export function transformBagProductToCheckoutProduct(
  product: BagDetailedProduct,
  seller: BagDetailedSellerInfo
): CheckoutProduct {
  const checkoutProduct: CheckoutProduct = {
    id: product.product_id,
    slug: product.slug,
    countryCode: product.country_code,
    categoryId: product?.category_id,
    price: {
      nationalShippingCost: product.pricing.national_shipping_cost?.total_price,
      internationalShippingCost:
        product.pricing.international_shipping_cost?.total_price,
      discountedPriceAmount: product.pricing.discounted_price?.total_price,
      currencyName: product.pricing.currency_name,
      priceAmount: product.pricing.original_price.total_price,
    },
    seller: {
      id: seller?.id,
      username: seller?.username,
      picture: seller?.picture
        ? (filterRequiredPictures(seller.picture) as PictureFormat[])
        : [],
      initials: seller.initials,
      verified: seller.verified,
      firstName: seller.first_name,
      lastName: seller.last_name,
      reviewsTotal: seller?.reviews_total,
      reviewsRating: seller?.reviews_rating,
    },
    pictures:
      product?.pictures?.map((picture) => filterRequiredPictures(picture)) ||
      [],
    description: product.description,
    brandName: product.brand_name,
    productType: product.product_type,
  };

  if (product.variant_id) {
    checkoutProduct.size = {
      id: product.variant_id.toString(),
    };
  }

  if (product.size) {
    checkoutProduct.size = {
      ...checkoutProduct.size,
      name: product.size,
    };
  }

  return checkoutProduct;
}

export function getGuestBagItems(): UpdateBagRequest {
  try {
    const guestBagItems = getLocalStorageItem(
      GUEST_BAG_ITEMS_LOCAL_STORAGE_KEY
    );
    return safeJsonParse(guestBagItems, []);
  } catch {
    return [];
  }
}

export function clearGuestBagItems() {
  removeLocalStorageItem(GUEST_BAG_ITEMS_LOCAL_STORAGE_KEY);
}

export async function getBagBasic(isAuth: boolean) {
  if (isAuth) {
    const res = await getAuthBagBasic();

    let count = Number(res.headers.get('X-Item-Count')) || 0;

    if (count === 0 && res.data.products) {
      count = Object.values(res.data.products).reduce((total, items) => {
        return (
          total +
          items.reduce((itemTotal, item) => {
            return itemTotal + (item.quantity || 1);
          }, 0)
        );
      }, 0);
    }
    return {
      count,
      products: res.data.products,
    };
  }

  const items = getGuestBagItems();
  let count = 0;
  const products = items.reduce(
    (acc, val) => {
      if (!acc[val.product_id]) {
        acc[val.product_id] = [];
      }
      acc[val.product_id].push({
        product_id: val.product_id,
        variant_id: val.variant_id,
        quantity: val.quantity ?? 1,
      });
      count += val.quantity ? Number(val.quantity) : 1;
      return acc;
    },
    {} as Record<string, BagBasicProduct[]>
  );

  return {
    count,
    products,
  };
}

export async function getBagDetailed({
  isAuth,
  language,
  includeOffers,
  includeShippingInTotals,
  forceFeeCalculation,
}: {
  isAuth: boolean;
  language: string;
  includeOffers?: boolean;
  includeShippingInTotals?: boolean;
  forceFeeCalculation: boolean;
}) {
  if (isAuth) {
    const res = await getAuthBagDetailed({
      lang: language,
      includeOffers,
      includeShippingInTotals,
      forceFeeCalculation,
    });
    return res.data;
  }
  const bagItems = getGuestBagItems();
  if (!bagItems.length) {
    return { sellers: [] };
  }
  const res = await getGuestBagDetailed({
    lang: language,
    bagItems,
    forceFeeCalculation,
    includeShippingInTotals,
  });
  return res.data;
}

export function updateGuestBag(
  newBagRequest: UpdateBagRequest
): Promise<UpdateBagRequest> {
  return new Promise((resolve) => {
    const guestBagItems = getGuestBagItems();
    const clonedGuestBagItems = [...guestBagItems];

    newBagRequest.forEach((newItem) => {
      const index = clonedGuestBagItems.findIndex(
        (existingItem) =>
          existingItem.product_id === newItem.product_id &&
          existingItem.variant_id === newItem.variant_id
      );
      if (newItem.quantity === 0 && index >= 0) {
        // Deleting an item
        clonedGuestBagItems.splice(index, 1);
      }
      if (newItem.quantity > 0 && index < 0) {
        // Adding an item
        clonedGuestBagItems.push(newItem);
      }
    });

    setLocalStorageItem(
      GUEST_BAG_ITEMS_LOCAL_STORAGE_KEY,
      JSON.stringify(clonedGuestBagItems)
    );

    resolve(clonedGuestBagItems);
  });
}

export function updateBag(
  isAuth: boolean,
  data: UpdateBagRequest
): Promise<AxiosCompatibleResponse<void> | UpdateBagRequest> {
  return isAuth ? updateAuthBag(data) : updateGuestBag(data);
}

export async function syncGuestBag(userId: number) {
  const items = getGuestBagItems();
  // remove any items from bag that might be owned by the current user
  const filteredItems = items.filter((item) => item.seller_id !== userId);
  if (filteredItems.length > 0) {
    try {
      await updateAuthBag(filteredItems);
    } catch {
      // If there was a problem syncing the bag then abort any attempt to visit the checkout page on redirect
      clearCheckoutProducts();
    }
  }
  if (items.length > 0) {
    clearGuestBagItems();
  }
}

export function getEstimatedShippingPrice(params: ShippingPriceParams) {
  const {
    nationalShippingCost,
    internationalShippingCost,
    buyerLocation,
    sellerLocation,
  } = params;
  if (sellerLocation.toLowerCase() === buyerLocation?.toLowerCase()) {
    return nationalShippingCost;
  }
  return internationalShippingCost;
}

export function getDetailedBagItemCount(data: BagDetailedCache) {
  return data.sellers.reduce((acc, cur) => {
    acc += cur.products.length;
    return acc;
  }, 0);
}

export function getNonoptimisticProducts(
  products: BagDetailedSellerCache['products']
) {
  return products.filter(
    (product): product is BagDetailedProduct =>
      !('is_optimistic' in product) || !product.is_optimistic
  );
}

export function getBagViewTrackingArgs(sellers: BagDetailedSellerCache[]) {
  return sellers.reduce<Required<BagViewArgs>>(
    (acc, seller) => {
      return {
        ...acc,
        productIds: [
          ...acc.productIds,
          ...seller.products
            .filter(
              (product): product is BagDetailedProduct =>
                !('is_optimistic' in product) || !product.is_optimistic
            )
            .reduce<number[]>((productIds, product) => {
              if (!isSold(product.status)) {
                return [...productIds, product.product_id];
              }
              return productIds;
            }, []),
        ],
        soldProductIds: [
          ...acc.soldProductIds,
          ...seller.products
            .filter(
              (product): product is BagDetailedProduct =>
                !('is_optimistic' in product) || !product.is_optimistic
            )
            .reduce<number[]>((soldProductIds, product) => {
              if (isSold(product.status)) {
                return [...soldProductIds, product.product_id];
              }
              return soldProductIds;
            }, []),
        ],
      };
    },
    {
      productIds: [],
      soldProductIds: [],
    }
  );
}

export function sendGtmBagView(
  products: Array<BagDetailedProduct>,
  defaultCurrency: CurrencyCode
) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        items: products.map((product) => ({
          item_id: product.variant_id
            ? `${product.product_id}-${product.variant_id}`
            : product.product_id.toString(),
          item_name: product.description,
          item_category: product.category_id?.toString(),
          item_variant: product.variant_id,
          price: parseFloat(
            product.pricing.original_price.price_breakdown.price.amount
          ),
          currency: product.pricing.currency_name,
          quantity: 1,
        })),
        value: products.reduce((acc, product) => {
          return (
            acc +
            parseFloat(
              product.pricing.original_price.price_breakdown.price.amount
            )
          );
        }, 0),
        currency: products.length
          ? products[0].pricing.currency_name
          : defaultCurrency,
      },
    });
  }
}

/**
 * When optimistically updating the detailed bag cache we need
 * to generate a placeholder record for the newly added product
 * and position it in the sellers array in the cache.
 * This helper does the heavy lifting to generate a new sellers array
 * based on this logic.
 */
export function generateOptimisticDetailedBagSellers({
  bagCache,
  request,
  currency,
}: {
  bagCache: BagDetailedCache;
  request: UpdateBagProduct;
  currency: CurrencyCode;
}) {
  const existingSeller = bagCache.sellers.find(
    (seller) =>
      seller.seller.id === request.seller_id &&
      seller.summary.currency === currency
  );

  let newSellers: BagDetailedCache['sellers'];
  if (existingSeller) {
    let sellerIndex = -1;
    newSellers = bagCache.sellers.map((seller, index) => {
      if (
        seller.seller.id === request.seller_id &&
        seller.summary.currency === currency
      ) {
        sellerIndex = index;
        return {
          seller: seller.seller,
          products: [
            {
              is_optimistic: true,
              product_id: request.product_id,
              variant_id: request.variant_id,
            },
            ...seller.products,
          ],
          summary: { currency, is_optimistic: true },
        };
      }
      return seller;
    });

    if (sellerIndex > -1) {
      // Push seller to start of array as most recently updated
      const matchedSeller = newSellers.splice(sellerIndex, 1);
      newSellers.splice(0, 0, ...matchedSeller);
    }
  } else {
    newSellers = [
      {
        seller: { is_optimistic: true, id: request.seller_id },
        products: [
          {
            is_optimistic: true,
            product_id: request.product_id,
            variant_id: request.variant_id,
          },
        ],
        summary: { currency, is_optimistic: true },
      },
      ...bagCache.sellers,
    ];
  }

  return newSellers;
}
