type Weighting = number;
type Identifier = string;

export type Experiment = {
  name: string;
  variants: [Weighting, Identifier][];
  parentExperiment?: {
    name: string;
    /**
     * If the user is in this of parent experiment they will
     * be forced into the control group of the child experiment
     */
    controlConditionGroup: string | ((arg: string) => string);
  };
  winningVariant?: null | string;
};

export type ActiveExperiment = {
  variant: string;
} & Partial<Experiment>;

export type ActiveExperiments = ActiveExperiment[];

export interface CurrentExperimentConfig {
  [key: string]: Experiment;
}

/**
 * Types for JIT/Optimizely underneath this line
 */

/**
 * These types are what we send to the backend for
 * both bucketing and preview calls
 */
export type OpzlyExperimentFlag = {
  name: string;
  override?: string;
};

export type FetchExperimentsBody = {
  flags: OpzlyExperimentFlag[];
};

/**
 * Cached* types define what we store in the RQ cache
 */
export type CachedVariant = { decision: string; bucketed: boolean };

export type CachedExperiments = Record<string, CachedVariant>;

/**
 * These types and helpers are for the static experiments config
 */
type OpzlyExperimentConfig = { control: string; [k: string]: string | boolean };

/* Taken from https://stackoverflow.com/a/54598743 so we can later get a union of all valid exp names */
export function inferExperimentKeys<T>(et: {
  [K in keyof T]: OpzlyExperimentConfig;
}) {
  return et;
}

export type Variant = {
  variant: string;
};

export type FetchExperimentsResponse = Record<string, Variant>;
