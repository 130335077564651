import {
  BAG_BASIC_ENDPOINT,
  BAG_DETAILED_ENDPOINT,
  UPDATE_BAG_ENDPOINT,
} from '@/constants/endpoints';
import {
  FetchBagBasicResponse,
  FetchBagDetailedResponse,
  UpdateBagRequest,
} from '@/modules/bag/types';
import { AxiosCompatibleResponse } from '@/modules/http/types';
import http from '@/modules/http';

export function getAuthBagBasic(): Promise<
  AxiosCompatibleResponse<FetchBagBasicResponse>
> {
  return http.get(BAG_BASIC_ENDPOINT, { withAuth: true });
}

export function getAuthBagDetailed({
  lang,
  includeShippingInTotals,
  includeOffers,
  forceFeeCalculation,
}: {
  lang: string;
  includeShippingInTotals?: boolean;
  includeOffers?: boolean;
  forceFeeCalculation: boolean;
}): Promise<AxiosCompatibleResponse<FetchBagDetailedResponse>> {
  return http.get(BAG_DETAILED_ENDPOINT, {
    withAuth: true,
    params: {
      lang,
      include_shipping_in_totals: includeShippingInTotals,
      include_offers: includeOffers,
      force_fee_calculation: forceFeeCalculation ? 'true' : 'false',
    },
  });
}

export function getGuestBagDetailed({
  lang,
  bagItems,
  forceFeeCalculation,
  includeShippingInTotals,
}: {
  lang: string;
  bagItems: UpdateBagRequest;
  forceFeeCalculation: boolean;
  includeShippingInTotals?: boolean;
}): Promise<AxiosCompatibleResponse<FetchBagDetailedResponse>> {
  const reqBody = bagItems;
  return http.post(BAG_DETAILED_ENDPOINT, reqBody, {
    withAuth: false,
    params: {
      lang,
      include_shipping_in_totals: includeShippingInTotals,
      force_fee_calculation: forceFeeCalculation ? 'true' : 'false',
    },
  });
}

export function updateAuthBag(
  data: UpdateBagRequest
): Promise<AxiosCompatibleResponse<void>> {
  return http.patch(UPDATE_BAG_ENDPOINT, data, { withAuth: true });
}
