import { User } from './types';

import { X_USER_DATA } from '@/constants/headers';
import { recordMetric } from '@/modules/observability/metrics';
import { Stdv1Stat } from '@/modules/observability/constants';
import { safeJsonParse } from '@/modules/storage/helpers';

const headers = import('next/headers');

export async function SERVERSIDE_getUserData(): Promise<User | null> {
  const headersFunction = await headers;
  try {
    const userData = (await headersFunction.headers()).get(X_USER_DATA);
    if (userData) {
      /**
       * Decoding the user data from the headers, set in middleware.
       * The user data could contain emojis or other characters that are non-ASCII, which is why it is encoded.
       */
      return safeJsonParse(decodeURIComponent(userData), null);
    }

    return null;
  } catch {
    recordMetric({
      type: 'increment',
      stat: Stdv1Stat['errors.user.parse.failed'],
    });

    return null;
  }
}
