import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  BucketUserArgs,
  bucketUserExperiments,
  getPreviewExperiments,
} from './api';

import { OpzlyExperimentName } from '@/modules/experiments/config';
import { sessionStorageSessionIdKey } from '@/modules/activityTracker/constants';
import {
  CachedExperiments,
  FetchExperimentsResponse,
} from '@/modules/experiments/types';
import { getSessionStorageItem } from '@/modules/storage';
import { getPersistentId } from '@/modules/activityTracker/helpers';
import { RQ_PREVIEW_EXPS_CACHE } from '@/modules/ReactQuery/cacheKeys';
import {
  AxiosCompatibleError,
  AxiosCompatibleResponse,
} from '@/modules/http/types';
import { useCurrentLocation } from '@/modules/location/useCurrentLocation';
import { getLogger } from '@/modules/observability/logging';
import { getExperimentsDecision } from '@/modules/experiments/helpers';

const BUCKET_RETRIES = 5;
const MUTATION_KEY = 'BUCKET_USER_MUTATION';

type HookResult = Record<string, string>;
type HookResultWithBucketer = [HookResult, () => void];

type UseExperimentsOptions<D> = {
  deferred?: D;
};

/**
 * Hook to use JIT Configured Optimizely experiments.
 * @param experimentNames Experiment names passed in **must** be configured in configs/optimizely.ts
 */
export function useExperiments(
  experimentNames: OpzlyExperimentName[]
): HookResult;
export function useExperiments<D extends boolean>(
  experimentNames: OpzlyExperimentName[],
  options?: UseExperimentsOptions<D>
): D extends true ? HookResultWithBucketer : HookResult;

export function useExperiments<D extends boolean>(
  experimentNames: OpzlyExperimentName[],
  options?: UseExperimentsOptions<D>
) {
  const client = useQueryClient();
  const { location } = useCurrentLocation();

  const bucketUserMutation = useMutation<
    AxiosCompatibleResponse<FetchExperimentsResponse>,
    AxiosCompatibleError,
    Omit<BucketUserArgs, 'persistentId'>
  >({
    mutationFn: async (args: Omit<BucketUserArgs, 'persistentId'>) => {
      const persistentId = (await getPersistentId()) as string;
      return bucketUserExperiments({ ...args, persistentId });
    },

    retry: BUCKET_RETRIES,

    // e.g. 3 * 100 == 300ms delay before retry #4
    retryDelay: (count) => count * 100,
  });

  const previousPreviewExperimentsCache =
    client.getQueryData<CachedExperiments>([RQ_PREVIEW_EXPS_CACHE]);

  /**
   * Preview experiments are fetched server-side on page load. This useQuery hook
   * serves two important purposes:
   *  1. it sets the caching and TTL to Infinity for the preview experiments data so
   *     that it is never garbage collected
   *  2. it contains the code to perform a refetch client-side if the cache is
   *     invalidated manually (e.g. after signup)
   */
  const { data: cachedExps } = useQuery({
    queryKey: [RQ_PREVIEW_EXPS_CACHE],

    queryFn: async () => {
      const res = await getPreviewExperiments({
        persistentId: (await getPersistentId()) as string,
        countryCode: location,
      });

      /**
       * As in the original server-side fetch, we augment the result with the `bucketed`
       * boolean to track whether the experiment has been seen yet.
       * On refetch we need to retain this information, so we access the previous contents
       * of the query cache.
       */
      return Object.keys(res.data).reduce<CachedExperiments>((acc, val) => {
        acc[val] = {
          decision: res.data[val].variant,
          bucketed: previousPreviewExperimentsCache?.[val].bucketed ?? false,
        };
        return acc;
      }, {});
    },
    gcTime: Infinity,
  });

  function bucket() {
    if (typeof window === 'undefined') {
      getLogger().error(
        'Please ensure bucketing happens in a client-side operation for these experiments',
        { experimentNames }
      );

      throw new Error(
        `Bucketing attempt detected on the server. Please check the server logs.`
      );
    }

    /**
     * Due to race conditions when rendering this hook in a mapped list (e.g. product list)
     * it's possible that we could send multiple bucketing calls by mistake if this hook
     * is invoked in quick succession.
     *
     * This code checks if there are running mutations with the same experiment names as
     * passed in, to prevent duplicate calls.
     */
    const runningBucketCalls = client.isMutating({
      mutationKey: [MUTATION_KEY],
      predicate: (mutation) => {
        return (
          mutation.state.variables as { experimentNames: string[] }
        )?.experimentNames.every(
          (name: string, index) => name === experimentNames[index]
        );
      },
    });

    if (runningBucketCalls > 0) {
      return;
    }

    /**
     * Compare experiment names passed into the hook with experiments
     * we have in the preview cache. Exclude any experiments from the
     * bucketing call if they have previously been marked as bucketed
     * in the cache.
     */
    const expsToBucket = experimentNames.filter((expName) => {
      return cachedExps?.[expName] && cachedExps[expName].bucketed === false;
    });

    if (expsToBucket.length) {
      /**
       * Optimistically update the cache before the network call
       * with bucketed: true for each experiment passed into the
       * hook to avoid race conditions. If the call fails we then
       * set them back to false below.
       */
      client.setQueryData(
        [RQ_PREVIEW_EXPS_CACHE],
        (cached: CachedExperiments | undefined) => {
          const updateableCopy = { ...cached };

          for (const exp of experimentNames) {
            if (updateableCopy[exp]) {
              updateableCopy[exp].bucketed = true;
            }
          }

          return updateableCopy;
        }
      );

      bucketUserMutation.mutate(
        {
          experimentNames: expsToBucket,
          countryCode: location,
          sessionId: getSessionStorageItem(
            sessionStorageSessionIdKey
          ) as string,
          date: Date.now(),
        },
        {
          /**
           * Mark experiments in cache as bucketed once user has been bucketed
           * as to prevent bucketing them multiple times in a session
           */
          onSuccess: (
            res: AxiosCompatibleResponse<FetchExperimentsResponse>
          ) => {
            for (const exp in res.data) {
              /**
               * We've been asked to keep track of any mismatches as the
               * variants in the cache (preview endpoint) should be exactly
               * the same as the variants returned after a successful bucket
               */
              if (cachedExps?.[exp]?.decision !== res.data?.[exp]?.variant) {
                getLogger().warn(
                  `[JIT Bucketing] cached variant mismatch for ${exp}`
                );
              }
            }
          },
          onError: (error: AxiosCompatibleError | Error) => {
            /**
             * If the bucketing call failed, make sure to set bucketed back
             * to false here so we can attempt again in the future
             */
            client.setQueryData(
              [RQ_PREVIEW_EXPS_CACHE],
              (cached: CachedExperiments | undefined) => {
                const updateableCopy = { ...cached };

                for (const exp of experimentNames) {
                  if (updateableCopy[exp]) {
                    updateableCopy[exp].bucketed = false;
                  }
                }

                return updateableCopy;
              }
            );

            getLogger().warn(
              `[JIT Bucketing] bucketing call failed for ${expsToBucket.join(
                ', '
              )} after ${BUCKET_RETRIES} retries.`,
              {
                error: {
                  message:
                    (error as AxiosCompatibleError)?.response?.data ||
                    (error as Error)?.message,
                  stack: (error as Error)?.stack,
                },
                http: {
                  status_code: (error as AxiosCompatibleError)?.code,
                },
              }
            );
          },
        }
      );
    }
  }

  useEffect(() => {
    /**
     * If cachedExps is null or undefined, assume the preview endpoint
     * call failed and don't try and bucket users. Instead, we return
     * the fallbackExps object defined above which should give users
     * the control experience.
     */
    if (!cachedExps) {
      return;
    }

    /**
     * If the user has specified deferred bucketing, it means
     * they want to control when bucketing occurs rather than
     * letting the hook bucket on mount
     */
    if (options?.deferred) {
      return;
    }

    bucket();
  }, []);

  const returnValue = getExperimentsDecision(experimentNames, cachedExps);

  return options?.deferred ? [returnValue, bucket] : returnValue;
}
