'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import clsx from 'clsx';

import styles from './styles.module.css';

import { CurrencyCode } from '@/modules/currencies/constants';
import { LocaleCurrencyFormat } from '@/components/LocaleCurrencyFormat';

type Props = {
  currencyCode: CurrencyCode;
  price: string;
  ariaLabel?: string;
  discountedPrice?: string;
  className?: string;
  inPreview?: boolean;
};

/**
 * @TECHBEBT PB 1/2/24
 * The render logic in this component is hard to follow and
 * could be simplified.
 */
export function Price({
  currencyCode,
  price,
  discountedPrice,
  className = '',
  ariaLabel,
  inPreview,
}: Props) {
  const t = useTranslations('common');
  const isDiscounted =
    typeof discountedPrice !== 'undefined' && discountedPrice !== null;

  function fullPriceComponent() {
    if (isDiscounted) {
      return (
        <Text
          className={clsx(styles.price, {
            [styles.discountedFullPrice]: isDiscounted,
          })}
          aria-label={ariaLabel || t('FullPrice')}
          type="caption1"
          bold={inPreview && !isDiscounted}
        >
          <LocaleCurrencyFormat
            currencyCode={currencyCode}
            numberToFormat={price}
          />
        </Text>
      );
    }

    return (
      <Text
        className={clsx(styles.price, {
          [styles.discountedFullPrice]: isDiscounted,
        })}
        aria-label={ariaLabel || t('Price')}
        bold={inPreview && !isDiscounted}
      >
        <LocaleCurrencyFormat
          currencyCode={currencyCode}
          numberToFormat={price}
        />
      </Text>
    );
  }

  function discountedPriceComponent() {
    return isDiscounted ? (
      <Text
        bold
        type="text"
        className={styles.price}
        aria-label={t('DiscountedPrice')}
      >
        <LocaleCurrencyFormat
          currencyCode={currencyCode}
          numberToFormat={discountedPrice}
        />
      </Text>
    ) : null;
  }

  if (!isDiscounted) {
    return <div className={className}>{fullPriceComponent()}</div>;
  }

  return (
    <div className={clsx(className)}>
      {fullPriceComponent()} {discountedPriceComponent()}
    </div>
  );
}
