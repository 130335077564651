import { ObjectToCamel } from 'ts-case-convert/lib/caseConvert';

import { CurrencyCode } from '@/modules/currencies/constants';
import { SimilarPriority } from '@/modules/likes/types.ts';
import { TransformedPriceEstimateDictionary } from '@/modules/price/types.ts';

export type ThumbnailFormat = {
  url: string;
  width: number;
  height: number;
};

export type PictureFormat = ThumbnailFormat & {
  id: number;
};

export type PictureFormatV2 = ThumbnailFormat & {
  id: string;
  initialResolutionUrl?: string;
};

export enum LegacyProductStatus {
  ONSALE = 'ONSALE',
  PURCHASED = 'PURCHASED',
  DELETED = 'DELETED',
  DELETED_ONSALE = 'DELETED_ONSALE',
  DELETED_PURCHASED = 'DELETED_PURCHASED',
  DELETED_MARKED_AS_SOLD = 'DELETED_MARKED_AS_SOLD',
  MARKED_AS_SOLD = 'MARKED_AS_SOLD',
  PRODUCT_BANNED_ONSALE = 'PRODUCT_BANNED_ONSALE',
  USER_BANNED_ONSALE = 'USER_BANNED_ONSALE',
  PRODUCT_BANNED_PURCHASED = 'PRODUCT_BANNED_PURCHASED',
  USER_BANNED_PURCHASED = 'USER_BANNED_PURCHASED',
}

export enum ProductStatus {
  ONSALE = 'S',
  PURCHASED = 'P',
  DELETED = 'X',
  DELETED_ONSALE = 'x',
  DELETED_PURCHASED = 'p',
  DELETED_MARKED_AS_SOLD = 'm',
  MARKED_AS_SOLD = 'M',
  PRODUCT_BANNED_ONSALE = 'B',
  USER_BANNED_ONSALE = 'b',
  PRODUCT_BANNED_PURCHASED = 'C',
  USER_BANNED_PURCHASED = 'c',
  // USER_INACTIVE does not have a database char equivalent
  USER_INACTIVE = 'USER_INACTIVE',
}

export enum BackendProductStatus {
  ONSALE = 'STATUS_ONSALE',
  PURCHASED = 'STATUS_PURCHASED',
  MARKED_AS_SOLD = 'STATUS_MARKED_AS_SOLD',
  DELETED = 'STATUS_DELETED',
  DELETED_ONSALE = 'STATUS_DELETED_ONSALE',
  DELETED_PURCHASED = 'STATUS_DELETED_PURCHASED',
  DELETED_MARKED_AS_SOLD = 'STATUS_DELETED_MARKED_AS_SOLD',
  PRODUCT_BANNED_ONSALE = 'STATUS_PRODUCT_BANNED_ONSALE',
  USER_BANNED_ONSALE = 'STATUS_USER_BANNED_ONSALE',
  PRODUCT_BANNED_PURCHASED = 'STATUS_PRODUCT_BANNED_PURCHASED',
  USER_BANNED_PURCHASED = 'STATUS_USER_BANNED_PURCHASED',
}

export type Price = {
  currencyName: CurrencyCode;
  priceAmount: string;
  discountedPriceAmount?: string;
  discountPercentage?: number | string;
  internationalShippingCost?: string;
  nationalShippingCost?: string;
  taxInclusivePriceAmount?: string;
  taxInclusiveDiscountedPriceAmount?: string;
};

export type LegacyPrice = {
  currency_name: CurrencyCode;
  price_amount: string;
  currency_symbol: string;
  discount_percentage?: string;
  discounted_price_amount?: string;
  international_shipping_cost?: string;
  national_shipping_cost?: string;
  tax_inclusive_price_amount?: string;
  tax_inclusive_discounted_price_amount?: string;
};

export enum OfferStatus {
  ACCEPTED = 'ACCEPTED',
  EXPIRED = 'EXPIRED',
  OFFERER_COUNTERED = 'OFFERER_COUNTERED',
  BINDING_OFFERER_COUNTERED = 'BINDING_OFFERER_COUNTERED',
  PENDING = 'PENDING',
  BINDING_PENDING = 'BINDING_PENDING',
  REDEEMED = 'REDEEMED',
  REJECTED = 'REJECTED',
  RESCINDED = 'RESCINDED',
  SELLER_COUNTERED = 'SELLER_COUNTERED',
  SELLER_INITIATED = 'SELLER_INITIATED',
  UNAVAILABLE = 'UNAVAILABLE',
}

type OfferOriginator = 'OFFERER' | 'SELLER';

export type Offer = {
  currentOfferFrom: OfferOriginator;
  expiresAt: string;
  hasAnotherActiveOffer: boolean;
  isStillNegotiable: boolean;
  offerCurrency: CurrencyCode;
  offerId: string;
  offerStatus: OfferStatus;
  offerValue: string;
  offerersHighestOfferValue: string;
  originalOfferFrom: OfferOriginator;
  productId: number;
  sellerId: number;
};

export type Preview = Partial<{
  150: string;
  210: string;
  320: string;
  480: string;
  640: string;
  960: string;
  1280: string;
}>;

export type FormattedVideos = {
  sources: Array<{
    url: string;
    format: string;
  }>;
  thumbnails: Array<ThumbnailFormat>;
};

export type Seller = {
  verified: boolean;
  initials: string;
  id: number;
  picture?: PictureFormat[];
  username: string;
  reviewsTotal?: number;
  reviewsRating?: number;
  itemsSold?: number;
  lastSeen?: string;
  firstName: string;
  lastName: string;
  averageShippingDays?: number;
};

export type SearchListProduct = {
  id: number;
  slug: string;
  pricing: TransformedPriceEstimateDictionary;
  has_video: boolean;
  preview: Preview;
  date_created?: string;
  pictures?: Preview[];
  status: string;
  sold?: boolean;
  variant_set_id?: number;
  variants?: Record<string, number>;
  is_liked?: boolean;
  seller_user_name?: string;
  reviews_rating?: number;
  total_reviews?: number;
  is_boosted?: boolean;
  page_number?: number;
  sizes?: string[];
  category_id?: number;
  brand_id?: number;
  brand_name?: string;
  description?: string;
  purchase_ids?: number[];
};

export type ListProduct = {
  id: number;
  slug: string;
  price: Pick<Price, 'currencyName' | 'discountedPriceAmount' | 'priceAmount'>;
  hasVideo: boolean;
  preview: Preview;
  dateCreated?: string;
  pictures?: Preview[];
  status: string;
  sold?: boolean;
  variantSetId?: number;
  variants?: Record<string, number>;
  isLiked?: boolean;
  sellerUserName?: string;
  reviewsRating?: number;
  totalReviews?: number;
  isBoosted?: boolean;
  pageNumber?: number;
  sizes?: string[];
  categoryId?: number;
  brandId?: number;
  brandName?: string;
  description?: string;
  purchaseIds?: number[];
};

export type LegacyListProduct = {
  id: number;
  slug: string;
  price: Price;
  hasVideo: boolean;
  preview: Preview;
  dateCreated?: string;
  pictures?: Preview[];
  status: string;
  sold?: boolean;
  variantSetId?: number;
  variants?: Record<string, number>;
  isLiked?: boolean;
  sellerUserName?: string;
  reviewsRating?: number;
  totalReviews?: number;
  isBoosted?: boolean;
  pageNumber?: number;
  sizes?: string[];
  categoryId?: number;
  brandId?: number;
  brandName?: string;
  description?: string;
  purchaseIds?: number[];
};

export type LegacyProductById = {
  id: number;
  address: string;
  categoryId?: number;
  categoryName?: string;
  description: string;
  pictures: PictureFormat[][];
  quantity?: number | null;
  variantSetId?: number | null;
  countryCode: string;
  status: ProductStatus | LegacyProductStatus;
  productType: string;
  slug: string;
  price: Price;
  dateUpdated: string;
  videos?: FormattedVideos[];
  seller: Seller;
  shippingMethods: ShippingMethod[];
  likeCount?: number;
  brandId: number | null;
  brandName?: string;
  isLiked?: boolean;
  isSaved?: boolean;
  variants?: Record<number, number>;
};

export type StyleAttribute = {
  id: string;
  name: string;
};

export type Size = {
  id: string;
  name: string;
  quantity: number;
};

export type Attributes = Record<string, string[]>;

/**
 * moreItems/:sellerId/:productId/
 */
export type FetchMoreItemsMeta = {
  limit: number;
  cursor?: string;
  hasMore: boolean;
};

export type MoreProduct = {
  id: number;
  slug: string;
  preview: Preview;
  pictures: Preview[];
  has_video: boolean;
  pricing: TransformedPriceEstimateDictionary;
  sizes: string[];
  sold: boolean;
  status: ProductStatus;
  is_liked: boolean;
};

export type FetchMoreItemsResponse = {
  products: MoreProduct[];
  meta: FetchMoreItemsMeta;
};

export type FetchProductMoreItemsRequestParams = {
  limit: number;
  location: string;
  cursor?: string;
  priority?: SimilarPriority.Brand;
  brand_id?: number;
  seller_id?: string;
  force_fee_calculation?: boolean;
};

type ProductAttribute = {
  id: string;
  name: string;
};

export type ProductBrandAttribute = { is_known: boolean } & ProductAttribute;

export type ProductSize = {
  id: number;
  name: string;
  quantity: number;
  status: BackendProductStatus;
};

export type ProductMediaAssetFormat = Record<string, ThumbnailFormat>;

export type ProductVideo = {
  id: string;
  video_url: string;
  thumbnail: {
    formats: ProductMediaAssetFormat;
  };
  outputs: Array<{
    format: string;
    url: string;
  }>;
};

export type ProductPicture = {
  id: number;
  original_width?: number;
  original_height?: number;
  is_square: boolean;
  formats: ProductMediaAssetFormat;
};

type FormattedPicture = {
  id: string | number;
  width: number;
  height: number;
  url: string;
  initial_resolution_url?: string;
};

export type Seller_snake = {
  verified: boolean;
  initials: string;
  id: number;
  picture?: FormattedPicture[];
  username: string;
  reviews_total?: number;
  reviews_rating?: number;
  items_sold?: number;
  last_seen?: string;
  first_name: string;
  last_name: string;
  average_shipping_days?: number;
};

export function isProductWithSeller(
  product: ProductById
): product is ProductWithSeller {
  return !!product.seller;
}

type ShippingMethod = {
  parcel_size_id: 'small';
  payer: 'buyer';
  ship_from_address_id: 2800508;
  shipping_provider_id: 'MY_HERMES';
};

export type ProductById = {
  id: number;
  address: string;
  category_id: number;
  category_name?: string;
  country_code: string;
  brand_id?: number;
  brand_name?: string;
  pricing: TransformedPriceEstimateDictionary;
  date_updated: string;
  description: string;
  pictures: FormattedPicture[][];
  quantity?: number;
  status: string;
  videos?: FormattedVideos[];
  seller?: Seller_snake;
  variant_set_id?: number;
  like_count?: number;
  variants?: Record<string, number>;
  slug: string;
  shipping_methods?: ShippingMethod[];
  product_type: string | null;
};

export type ProductWithSeller = Omit<ProductById, 'seller'> & {
  seller: Seller_snake;
};

export type ProductBySlugResponse = {
  id: number;
  user_id: number;
  brand_id: number;
  active_status: 'active' | 'inactive';
  status: BackendProductStatus;
  category: number;
  description: string;
  updated_at: string;
  created_at: string;
  pictures?: ProductPicture[];
  location: string;
  country: string;
  shipping_method?: {
    shipping_id: string;
    parcel_size: string;
    payer: 'buyer' | 'seller';
    ship_from: number;
  };
  slug: string;
  variant_set_id?: number;
  variants_all?: Array<{
    id: number;
    quantity: number;
    status: BackendProductStatus;
  }>;
  videos?: ProductVideo[];
  is_boosted: boolean;
  seller: {
    id: number;
    verified: boolean;
    picture?: Record<string, string>;
    username: string;
    first_name: string;
    last_name: string;
    initials: string;
    country: string;
  };
};

export type ProductBySlugExtendedResponse = {
  is_liked?: boolean;
  is_saved?: boolean;
  is_product_owner: boolean;
  has_free_shipping: boolean;
  like_count?: number;
  offer?: {
    offer_id: string;
    offerer_id: number;
    seller_id: number;
    product_id: number;
    variant_set_id?: number;
    variant_id?: number;
    offer_value: string;
    expires_at: string;
    offer_currency: string;
    offer_status: OfferStatus;
    has_another_active_offer: boolean;
    current_offer_from: OfferStatus;
    is_still_negotiable: boolean;
    sellers_lowest_offer_value?: string;
    offerers_highest_offer_value?: string;
    original_offer_from: OfferStatus;
  };
  pricing: TransformedPriceEstimateDictionary;
  discount?: {
    discount_type?:
      | 'PERCENTAGE'
      | 'VALUE'
      | 'PERCENTAGE_FLASH_SALE'
      | 'VALUE_FLASH_SALE';
    display_discount_percentage?: number;
  };
  attributes: {
    product_title?: string;
    product_type?: string;
    group?: string;
    gender?: string;
    is_kids?: boolean;
    brand?: ProductBrandAttribute;
    condition?: ProductAttribute;
    colour?: ProductAttribute[];
    age?: ProductAttribute[];
    source?: ProductAttribute[];
    style?: ProductAttribute[];
    material?: ProductAttribute[];
    body_fit?: ProductAttribute[];
  };
  sizes?: ProductSize[];
  seller_reviews: {
    reviews_total?: number;
    reviews_rating?: number;
  };
  seller_activity: {
    items_sold?: number;
    last_seen?: string;
    average_shipping_days?: number;
  };
};

export type ProductListingBySlugResponse = {
  description: string;
  id: number;
  variantSetId: number;
  variants: Record<string, number>;
  slug: string;
  address: string;
  countryCode: string;
  categoryId: number;
  pictures: PictureFormat[];
  shippingMethods: ObjectToCamel<ShippingMethod>[];
  brandId: number;
  status: keyof ProductStatus;
  condition: string;
  price: Price;
  brand: string;
  group: string;
  attributes: Attributes;
  gender: string;
  isKids: boolean;
};

export type ProductPageProduct = ProductBySlugResponse &
  ProductBySlugExtendedResponse;

export type ProductCarouselInteraction =
  | 'ProductCarouselImageThumbnailClick'
  | 'ProductCarouselDotClick'
  | 'ProductImageArrowRight'
  | 'ProductImageArrowLeft'
  | 'ProductImageSwipeRight'
  | 'ProductImageSwipeLeft';

export type ProductPrice = {
  isReduced: boolean;
  currencyName: CurrencyCode;
  baseCurrentPrice: string;
  baseOriginalPrice: string;
  totalCurrentPrice: string;
  totalOriginalPrice: string;
  currentPriceFees?: string;
  currentSalesTax?: string;
};
