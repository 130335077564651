'use client';

import React from 'react';
import clsx from 'clsx';
import { VisuallyHidden } from '@depop/web-ui-kit/VisuallyHidden';

import styles from './styles.module.css';

type Props = {
  shape: 'circle' | 'rectangle';
  width: `${number}px` | `${number}%` | string;
  height: `${number}px` | `${number}%` | string;
} & React.HTMLAttributes<HTMLDivElement>;

function Placeholder({ shape, width, height, className = '', ...rest }: Props) {
  const dimensions = {
    '--width': width,
    '--height': height,
  } as React.CSSProperties;

  if (shape === 'circle') {
    return (
      <div
        className={clsx({
          [styles.circle]: true,
          [className]: Boolean(className),
        })}
        style={dimensions}
        {...rest}
      >
        <VisuallyHidden>Loading…</VisuallyHidden>
      </div>
    );
  }
  if (shape === 'rectangle') {
    return (
      <div
        className={clsx({
          [styles.rectangle]: true,
          [className]: Boolean(className),
        })}
        style={dimensions}
        {...rest}
      >
        <VisuallyHidden>Loading…</VisuallyHidden>
      </div>
    );
  }
  return null;
}

export { Placeholder };
