import React from 'react';

import { useCurrentLocation } from '@/modules/location/useCurrentLocation';
import { useCurrentLanguage } from '@/modules/language/useCurrentLanguage';
import { CurrencyCode } from '@/modules/currencies/constants';
import { getLocaleByLanguageAndLocation } from '@/modules/language/helpers';

type Props = {
  numberToFormat: number | string;
  currencyCode?: CurrencyCode;
};

function LocaleCurrencyFormat({ numberToFormat, currencyCode }: Props) {
  const { location } = useCurrentLocation();
  const { language } = useCurrentLanguage();

  const locale = getLocaleByLanguageAndLocation(language, location);

  //  If a product has no currency code, it has no price - this is a known issue. Checking here is a quick fix until we decide what to do about these products at a company level - Eli 10/23
  if (!currencyCode) {
    return <></>;
  }

  return (
    <React.Fragment>
      {new Intl.NumberFormat(locale, {
        currency: currencyCode,
        style: 'currency',
      }).format(Number(numberToFormat))}
    </React.Fragment>
  );
}

export { LocaleCurrencyFormat };
