import { FetchExperimentsBody, inferExperimentKeys } from './types';

/**
 * Define all of your JIT enabled experiments and variants here.
 *
 * The only requirement is that each one has the 'control' field
 * which should be the name of the control group set up in opzly.
 *
 * You can optionally define your variants. There is no strict
 * naming convention, as these are only to be used in your feat
 * code, e.g. `is_exp_enabled === THIS_CONST.my_exp.my_variant`
 */
export const VARIANT_IDENTIFIER = 'variant';
export const VARIANT_A_IDENTIFIER = 'varianta';
export const VARIANT_B_IDENTIFIER = 'variantb';
export const VARIANT_C_IDENTIFIER = 'variantc';
export const ENABLED_IDENTIFIER = 'enabled';
export const CONTROL_IDENTIFIER = 'control';
export const CHAMPION_IDENTIFIER = 'champion';
export const HOLDOUT_IDENTIFIER = 'holdout';
export const ON_IDENTIFIER = 'on';

export const OPTIMIZELY_EXPERIMENTS = inferExperimentKeys({
  web_5648_adobe_landing: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_upfront_fees: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_5698_bulk_listing_feature: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_6051_signup_username_population: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_magic_link_login_v2: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  mobile_magic_link_login_v2: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_productview_derived_metrics_test: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  magic_link_registration_web: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_6224_sold_items_bottom_of_the_shop: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_6367_shop_second_image_hover: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_magic_link_interstitial_landing_page: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_5780_hide_shipping_cost_on_bag_and_qab: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  sxp_listing_repop_photos: {
    control: CONTROL_IDENTIFIER,
    // This is a cross-platform flag so uses naming conventions defined by iOS
    on: 'on',
    off: 'off',
  },
  web_6073_split_ymal_into_more_sections: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_6308_view_bag_cta_on_pdp: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_6304_show_suggested_offer_in_pdp: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_6429_trending_search_suggestions_in_search: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_6284_visible_searchbar_on_mobile_homepage: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_6390_highlight_categories_on_homepage: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_6232_shop_mobile_filters: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_6431_move_shop_by_style_higher_on_homepage: {
    control: CONTROL_IDENTIFIER,
    variantA: VARIANT_A_IDENTIFIER,
  },
  web_6291_sellers_liked_items_module: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
  web_6785_bulk_ab: {
    control: CONTROL_IDENTIFIER,
    variant: VARIANT_IDENTIFIER,
  },
});

export type OpzlyExperimentName = keyof typeof OPTIMIZELY_EXPERIMENTS;

export const PREVIEW_EXPERIMENTS: FetchExperimentsBody = {
  flags: Object.keys(OPTIMIZELY_EXPERIMENTS).map((k) => ({
    name: k,
  })),
};
