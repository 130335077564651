import { ActivityTrackerEventType } from '../activityTracker/constants';
import { useActivityTracker } from '../activityTracker/useActivityTracker';
import { useCurrentUser } from '../user/useCurrentUser';

import { BagViewArgs } from './types';

import { useExternalTracking } from '@/modules/externalTracking/useExternalTracking';

export function useBagTracking() {
  const [, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent: sendATEvent } = useActivityTracker({
    userId: currentUser?.id,
  });
  const {
    ga: { sendEvent: sendGAEvent },
  } = useExternalTracking();

  function sendBagViewEvent({ productIds, soldProductIds }: BagViewArgs) {
    sendATEvent(ActivityTrackerEventType.BAG_VIEW, {
      schemaVersion: '4.0',
      productIds,
      soldProductIds,
    });
  }

  function sendAddItemToBagAction({
    baggedProductIds,
    baggedProductVariants,
    productIds,
  }: {
    baggedProductIds: number[];
    baggedProductVariants: Array<number | null>;
    productIds: number[];
  }) {
    sendATEvent(ActivityTrackerEventType.ADD_ITEM_TO_BAG_ACTION, {
      schemaVersion: '3.0',
      action: 'add',
      baggedProductIds,
      // an array of the existing bagged product quantities AND the newly added product quantity - we only support a qty of 1 for mvp
      baggedProductQuantities: baggedProductIds.map(() => 1),
      baggedProductVariants,
      productIds,
    });
  }

  function sendAddToBagGAEvent({
    id,
    currency,
    name,
    price,
    value,
  }: {
    id: string;
    currency: string;
    name: string;
    price: string;
    value: string;
  }) {
    sendGAEvent({
      event: 'add_to_cart',
      ecommerce: {
        currency,
        items: [
          {
            item_id: id,
            item_name: name,
            price,
            quantity: 1,
          },
        ],
        value,
      },
    });
  }

  function sendQuickAccessBagViewEvent() {
    sendATEvent(ActivityTrackerEventType.WEB_BAG_QUICK_ACCESS_VIEW, {
      schemaVersion: '1.0',
    });
  }

  function sendBagViewAction(bagEventType: 'quickAccessBag' | 'nav' | 'pdp') {
    sendATEvent(ActivityTrackerEventType.WEB_BAG_VIEW_ACTION, {
      schemaVersion: '1.0',
      bagEventType,
    });
  }

  function sendBagCheckoutAction(bagEventType: 'quickAccessBag' | 'bag') {
    sendATEvent(ActivityTrackerEventType.WEB_BAG_CHECKOUT_ACTION, {
      schemaVersion: '1.0',
      bagEventType,
    });
  }

  return {
    sendBagViewEvent,
    sendAddItemToBagAction,
    sendAddToBagGAEvent,
    sendQuickAccessBagViewEvent,
    sendBagViewAction,
    sendBagCheckoutAction,
  };
}
